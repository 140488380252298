import { LibLogger } from '!app/lib/logger';

const logMetaData = { logName: 'UrlUtils' };
const IMAGE_FORMATS = ['jpeg', 'png', 'webp'];

/**
 * Generate an image url with a specified width, size and file format
 *
 * @param {string} image url string
 * @param {Object} image options that is generated as a query string parameter
 * @returns {string || null} generated image url with the size and format
 */
export const getImageUrl = (imageUrl, options) => {
  const defaultOptions = {
    width: 100,
    height: 100,
    format: 'webp',
    max: false,
  };
  const { width, height, format, max } = Object.assign(defaultOptions, options);
  let hasError = false;
  let errorMessage;

  if (!imageUrl) {
    hasError = true;
    errorMessage = 'getImageUrl() image url is not defined';
  } else if (!IMAGE_FORMATS.includes(format)) {
    hasError = true;
    errorMessage = 'getImageUrl() image format is NOT supported';
  }

  if (hasError) {
    LibLogger.warn(errorMessage, logMetaData);
    return null;
  }

  const size = max ? `${width}x${height}|max` : `${width}x${height}`;
  const paramString = `size=${size}&format=${format}`;
  return imageUrl.includes('?')
    ? `${imageUrl}&${paramString}`
    : `${imageUrl}?${paramString}`;
};

/**
 * Generate an object of sized urls that have a specified width, size and file format
 *
 * @param {string} imageUrl url string
 * @param {Object} sizeObject object of sizes and their values
 * @param {Object} image options that is generated as a query string parameter
 * @param {Boolean} max True if image sizing should be proportional,
 *  false if height and weight should be absolute.
 * @returns {Object || null} generated image urls with the size and format
 */
export const getImageUrls = (imageUrl, sizeObject, format, max) => {
  const sizes = Object.keys(sizeObject);
  if (sizes.length === 0) {
    return null;
  }
  return sizes.reduce((map, size) => {
    const options = {
      width: sizeObject[size].WIDTH,
      height: sizeObject[size].HEIGHT,
      format,
      max,
    };
    const options2x = {
      width: sizeObject[size].WIDTH * 2,
      height: sizeObject[size].HEIGHT * 2,
      format,
      max,
    };
    return {
      ...map,
      [size.toLowerCase()]: getImageUrl(imageUrl, options),
      [`${size.toLowerCase()}_retina`]: getImageUrl(imageUrl, options2x),
    };
  }, {});
};

/**
 * Generate an image dimensions object by width and aspect ratio
 *
 * @param {number} width the base width in pixel
 * @param {number} aspectRatio the aspect ratio expressed as a fraction height / width
 * @returns {Object} the image dimenstions
 */
export const createImageDimensions = (width, aspectRatio) => ({
  WIDTH: Math.round(width),
  HEIGHT: Math.round(width * aspectRatio),
});

/**
 * Resolves image from hookup into a dictionary with name and url
 *
 * @param {object} img image object from hookup
 * @returns {Object} image name and url
 */
export const resolveImg = (img) => {
  return {
    name: img?.alt,
    url: img?.url,
  };
};

/**
 * Resolves image from hookup into a dictionary with name and icon
 *
 * @param {object} img image object from hookup
 * @returns {Object} image name and icon
 */
export const resolveIcon = (img) => {
  return {
    name: img?.alt,
    icon: img?.url,
  };
};

/**
 * Returns HSL colors based on image artwork hue. Used for Tile gradients
 *
 * @param {number} hue value associated with entity artwork
 * @returns {Object} base and secondary HSL values for gradient
 */
export const getHueColor = (hue) => {
  const BASE_COLOR = {
    h: hue,
    s: 50,
    l: 40,
  };
  const hslToHex = (h, s, l) => {
    const alpha = 100;
    const rgbVal = 255;
    const lWithAlpha = l / alpha;
    const a = (s * Math.min(lWithAlpha, 1 - lWithAlpha)) / alpha;
    const f = (n) => {
      // eslint-disable-next-line no-magic-numbers
      const k = (n + h / 30) % 12;
      // eslint-disable-next-line no-magic-numbers
      const color = lWithAlpha - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
      return (
        Math.round(rgbVal * color)
          // eslint-disable-next-line no-magic-numbers
          .toString(16)
          .padStart(2, '0')
      );
    };
    return `#${f(0)}${f(8)}${f(4)}`;
  };
  return hslToHex(BASE_COLOR.h, BASE_COLOR.s, BASE_COLOR.l);
};
