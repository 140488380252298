export const initHotJar = (id, sv) => {
  (function (h, o, t, j, a, r) {
    // eslint-disable-next-line
    h.hj =
      h.hj ||
      function () {
        // eslint-disable-next-line
        (h.hj.q = h.hj.q || []).push(arguments);
      };
    // eslint-disable-next-line
    h._hjSettings = { hjid: id, hjsv: sv };
    // eslint-disable-next-line
    a = o.getElementsByTagName('head')[0];
    // eslint-disable-next-line
    r = o.createElement('script');
    // eslint-disable-next-line
    r.async = 1;
    // eslint-disable-next-line
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    a.appendChild(r);
  })(window, document, '//static.hotjar.com/c/hotjar-', '.js?sv=');
};
