import { getLogger } from '@hulu/h3o-logger';

const LOGGERS = {
  COMPONENT: 'component',
  DEFAULT: 'hitch',
  HOC: 'hoc',
  LIB: 'lib',
  MIDDLEWARE: 'middleware',
  SERVICE: 'service',
};

// Different loggers for Hitch
export const ComponentLogger = getLogger(LOGGERS.COMPONENT);
export const DefaultLogger = getLogger(LOGGERS.DEFAULT);
export const HocLogger = getLogger(LOGGERS.HOC);
export const LibLogger = getLogger(LOGGERS.LIB);
export const MiddlewareLogger = getLogger(LOGGERS.MIDDLEWARE);
export const ServiceLogger = getLogger(LOGGERS.SERVICE);
