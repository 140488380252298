import { createImageDimensions } from '!app/lib/imageUtils';

export const LOWER_ENVS = [
  'staging',
  'develop_prod',
  'development',
  'test_staging',
];
export const DEV_STAGING_ENVS = ['development', 'staging', 'test_staging'];
export const HOST_PROD = 'https://www.hulu.com';

export const MODAL_NAME_SIGNUP = 'signup-modal';
export const SEPARATOR = ' • ';
export const SERIES = 'series';
export const MOVIE = 'movie';
export const NETWORK = 'network';
export const GENRE = 'genre';
export const COLLECTION = 'collection';
export const EPISODE = 'episode';
export const HUB = 'hub';

// Each key must matches the expected tolower of each network's name
export const PREMIUM_NETWORKS = {
  hbo: {
    program: 'hbo',
  },
  'hbo max': {
    program: 'hbomax',
  },
  max: {
    program: 'max',
  },
  'paramount+ with showtime': {
    program: 'paramount-plus-with-showtime',
  },
  showtime: {
    program: 'showtime',
  },
  cinemax: {
    program: 'cinemax',
  },
  starz: {
    program: 'starz',
  },
};

export const ENTER_KEY = 13;
export const NETWORKS = 'networks';
export const ORIGINALS = 'originals';
export const SPORTS = 'sports';
export const TV = 'tv';
export const KIDS = 'kids';
export const MOVIES = 'movies';
export const SASH_PRODUCT_INDEX = 1;
export const NOAH_PRODUCT_INDEX = 2;
export const LIVE_PRODUCT_INDEX = 3;
export const DISNEY_SASH_PRODUCT_INDEX = 11;
export const DISNEY_NOAH_PRODUCT_INDEX = 13;
export const DISNEY_LIVE_PRODUCT_INDEX = 14;
export const DISNEY_LIVE_NOAH_PRODUCT_INDEX = 15;
export const SASH = 'SASH';
export const NOAH = 'NOAH';
export const LIVE = 'LIVE';
export const DISNEY_BUNDLE = 'Hulu, Disney+, and ESPN+';
export const DISNEY_NOAH_BUNDLE = 'Hulu (No Ads), Disney+, and ESPN+';
export const DISNEY_LIVE_BUNDLE = 'Hulu + Live TV, Disney+, and ESPN+';
export const DISNEY_NOAH_LIVE_BUNDLE =
  'Hulu (No Ads) + Live TV, Disney+, and ESPN+';

/**
 * Collection types
 */
export const SIMPLE_COLLECTION = 'simple-collection';

export const packageMap = {
  SVOD: '2',
  NOAH: '14',
  SHOWTIME: '15',
  LIVETV: '16',
  HBO: '17',
  CINEMAX: '18',
  STARZ: '19',
  ENTERTAINMENT: '21',
  SPORTS: '22',
  SPANISH: '23',
  DISNEY_PLUS: '25',
  ESPN_PLUS: '26',
  HBO_MAX: '27',
};

export const CUSTOMER_TYPE_APPLE = '1';
export const CUSTOMER_TYPE_HULU = '100';
export const CUSTOMER_TYPE_SPOTIFY = '103';
export const CUSTOMER_TYPE_SPRINT = '104';

export const PARTNER_TYPE_APPLE = 'itunes';

export const FREE_PGID = ['1'];
export const ANONYMOUS_USER_ID = -1;
export const HULU_SESSION = '_hulu_session';
export const HULU_PGID = '_hulu_pgid';

export const COOKIES = {
  PROFILE_ID: '_hulu_pid',
  PROFILE_NAME: '_hulu_pname',
  HULU_UID: '_hulu_uid',
  GUID: 'guid',
  CART_ABANDONMENT: '_hulu_cart_abandonment',
  HULU_PLUS_CMP: {
    name: '_hulu_plus_cmp',
    seconds: 2592000, // 30 days
    keepOnLogout: true,
  },
  HULU_PLUS_CMP_URL: {
    name: '_hulu_plus_cmp_url',
    seconds: 2592000,
    keepOnLogout: true,
  },
};

export const ANON = 0; // non-subscriber
export const PARTIALLY_ENTITLED = 1; // is subscriber AND does not have the package; (if applicable) subscriber is enrolled into cohorts
export const PARTIALLY_ENTITLED_NOT_ENROLLED = 2; // is subscriber AND has the base package but NOT enrolled into cohorts
export const FULLY_ENTITLED = 3; // is subscriber AND has all the package and addons
export const INELIGIBLE = 4; // is subscriber AND ineligible
export const INACTIVE = 5; // when current program is inactive
export const PARTIALLY_ENTITLED_NOT_TO_ADDONS = 6; // is subscriber AND has the package but NOT the addon

export const SPLAT_MESSAGES = {
  INELIGIBLE_CODE: 'Ineligible',
  MAX_INELIGIBLE: 'User cannot have both HBO Classic and HBO MAX components.', // Ineligible for Max With Ads or Max No Ads Addon
  INELIGIBLE_SUBSCRIPTION_STATUS_CODE: 'IneligibleDueToSubscriptionStatus',
  INELIGIBLE_PARTNER_CODE: 'IneligibleDueToPartnerBilling',
  INVALID_CODE: 'Invalid',
  NO_ACCOUNT_CHANGE: 'No change to account',
  INELIGIBLE_PRODUCT_ALREADY_PURCHASED: 'IneligibleProductAlreadyPurchased',
  OTP_ALREADY_PURCHASED: 'OTP product has already been purchased by the user.',
};

// Placeholder text which gets replaced by the tooltip icon
export const TOOLTIP_PLACEHOLDER = '#';

export const PROGRAM_TYPE_HUMAN_CODE = 'Human Code';
export const PROGRAM_TYPE_PARTNER_CODE = 'Partner Code';
export const PROGRAM_TYPE_DECRYPTED_CODE = 'Decrypted Code';
export const PROGRAM_TYPE_VIP_CODE = 'VIP Code';
export const PROGRAM_TYPE_DEVICE_CODE = 'Device';

export const PROGRAMS_WITH_CODE = [
  PROGRAM_TYPE_HUMAN_CODE,
  PROGRAM_TYPE_PARTNER_CODE,
  PROGRAM_TYPE_DECRYPTED_CODE,
  PROGRAM_TYPE_VIP_CODE,
  PROGRAM_TYPE_DEVICE_CODE,
];

export const CTA_INPUT_PLACEHOLDER = {
  [PROGRAM_TYPE_HUMAN_CODE]: 'Promo Code',
  [PROGRAM_TYPE_PARTNER_CODE]: 'Promo Code',
  [PROGRAM_TYPE_DECRYPTED_CODE]: 'Promo Code',
  [PROGRAM_TYPE_VIP_CODE]: 'VIP Code',
  [PROGRAM_TYPE_DEVICE_CODE]: 'Device Activation Code',
};

export const PROGRAMS_WITH_CODE_EXCLUDE_DEVICE = [
  PROGRAM_TYPE_HUMAN_CODE,
  PROGRAM_TYPE_PARTNER_CODE,
  PROGRAM_TYPE_DECRYPTED_CODE,
  PROGRAM_TYPE_VIP_CODE,
];

// welcome variation constants
export const WELCOME_CONTROL = 0;
// PREMIEREDATEFORMAT will make sure that the "Coming [Month] [Year]" headline
// will always be assigned based on the east coast timezone in case a show premieres
// on the first day of a month (the premiereDate time being sent to Hitch is always midnight, east coast time)
// For example, if a show premieres on 11/1 at 12am ET, the equivalent time in PT would be in October. Instead of showing
// that the headline for the premiere of the show in October, we would like the headline to show November.
export const PREMIEREDATEFORMAT = {
  'en-US': { month: 'long', year: 'numeric', timeZone: 'America/New_York' },
};
export const PREMIEREDATEFORMAT_MMDDYY = {
  'en-US': { month: 'long', day: 'numeric', year: 'numeric' },
};
export const HULU_UPCOMING_SERIES_TITLE = 'Series Premiere';
export const HULU_UPCOMING_MOVIE_TITLE = 'Coming';

// TODO: for Samsung OOBE, ask IDM to switch to prefill_code and deprecate this.
export const HOTH_PREFILL_CODE_PARAM = 'publicAuthenticationCode';
export const PREFILL_CODE_PARAM = 'prefill_code';

export const START = 'NodeGeneralStartPage';
export const LANDING = 'NodeLandingPage';
export const SITEMAP = 'sitemapPage';
export const BROWSE = 'BrowsePage';

export const PAGETYPE = {
  [START]: 'startPage',
  [LANDING]: 'landingPage',
  [SITEMAP]: 'SitemapPage',
  [BROWSE]: 'BrowsePage',
};

export const CMS_PAGETYPE = {
  [START]: 'StartPage',
  [LANDING]: 'LandingPage',
  [BROWSE]: 'BrowsePage',
  start: 'StartPage',
  landing: 'LandingPage',
  micro: 'MicroPage',
};

export const TITLE_ART_SIZES = {
  SMALL: {
    WIDTH: 210,
    HEIGHT: 42,
  },
  MEDIUM: {
    WIDTH: 300,
    HEIGHT: 60,
  },
  LARGE: {
    WIDTH: 350,
    HEIGHT: 70,
  },
};

export const BILLING_TYPES = {
  STANDARD: 'standard',
  PREPAID_STANDARD: 'standardPrepaid',
  PREPAID_PROMOTIONAL: 'promotionalPrepaid',
};

export const PREPAID_BILLING_TYPES = [
  BILLING_TYPES.PREPAID_STANDARD,
  BILLING_TYPES.PREPAID_PROMOTIONAL,
];

export const BILLING_PARTNER = {
  HULU: 'Hulu',
  CLASSIC: 'Classic',
};

export const COHORTS = {
  SPLIT_BILLED: 'splitBilled',
};

// Subscriber in good standing.
export const ACTIVE_SUBSCRIBER = 'active';
// Paused subscriber
export const PAUSED_SUBSCRIBER = 'paused';
// Pending cancel subscriber
export const PENDING_CANCEL_SUBSCRIBER = 'pendingCancel';
// Cancelled subscriber.
export const CANCELLED_SUBSCRIBER = 'cancelled';
// Subscriber is on hold (e.g. can't charge user's card).
export const ON_HOLD_SUBSCRIBER = 'onHold';
// Third-party billed or other non-standard subscriber.
export const NON_STANDARD_SUBSCRIBER = 'non-standard';
// Eligible to add all product(s)
export const ELIGIBLE_TO_ALL = 'eligibleToAll';
// Only eligible to the addon
export const ELIGIBLE_TO_ADDON = 'eligibleToAddon';
// Entitled already to plan or addon.
export const ENTITLED = 'entitled';

export const AWS_REDIS_PORT = 6379;

export const IMAGE_STATUSES = {
  LOADING: 'loading',
  LOADED: 'loaded',
  ERROR: 'error',
  RETRYING: 'retrying',
};

export const BREAKPOINTS = {
  XSMALL: 320,
  SMALL: 375,
  MEDIUM: 768,
  LARGE: 1024,
  XLARGE: 1280,
  XXLARGE: 1600,
};

export const DEVICES = {
  DESKTOP: 'desktop',
  TABLET: 'tablet',
  MOBILE: 'mobile',
  ANY: 'any',
};

// standard image ratios for calculating sizes
/* eslint-disable no-magic-numbers */
const mastheadImageRatios = {
  horizonal: 9 / 16,
  vertical: 57 / 32,
  bgVertical: 3 / 2,
};
/* eslint-enable no-magic-numbers */

// some mobile images need to overfill their headers to compensate for very long mastheads
const mobileOverfillOffset = 83;
const mobileOverfillOffsetWithRibbon = 149;

export const MASTHEAD_HORIZONTAL_SIZES = {
  MEDIUM: createImageDimensions(
    BREAKPOINTS.MEDIUM,
    mastheadImageRatios.horizonal
  ),
  LARGE: createImageDimensions(
    BREAKPOINTS.LARGE,
    mastheadImageRatios.horizonal
  ),
  XLARGE: createImageDimensions(
    BREAKPOINTS.XLARGE,
    mastheadImageRatios.horizonal
  ),
  XXLARGE: createImageDimensions(
    BREAKPOINTS.XXLARGE,
    mastheadImageRatios.horizonal
  ),
};

export const MASTHEAD_VERTICAL_SIZES = {
  XSMALL: createImageDimensions(
    BREAKPOINTS.XSMALL,
    mastheadImageRatios.vertical
  ),
  SMALL: createImageDimensions(BREAKPOINTS.SMALL, mastheadImageRatios.vertical),
};

export const MASTHEAD_BACKGROUND_VERTICAL_SIZES = {
  XSMALL: createImageDimensions(
    BREAKPOINTS.XSMALL,
    mastheadImageRatios.bgVertical
  ),
  SMALL: createImageDimensions(
    BREAKPOINTS.SMALL,
    mastheadImageRatios.bgVertical
  ),
};

export const DETAIL_MASTHEAD_BACKGROUND_VERTICAL_SIZES = {
  XSMALL: createImageDimensions(
    BREAKPOINTS.XSMALL,
    mastheadImageRatios.bgVertical
  ),
  SMALL: createImageDimensions(
    BREAKPOINTS.SMALL + mobileOverfillOffset,
    mastheadImageRatios.bgVertical
  ),
};

export const DETAIL_MASTHEAD__WITH_RIBBON_BACKGROUND_VERTICAL_SIZES = {
  XSMALL: createImageDimensions(
    BREAKPOINTS.XSMALL,
    mastheadImageRatios.bgVertical
  ),
  SMALL: createImageDimensions(
    BREAKPOINTS.SMALL + mobileOverfillOffsetWithRibbon,
    mastheadImageRatios.bgVertical
  ),
};

export const SEO_SCHEMA_TYPES = {
  SERIES: 'TVSeries',
  MOVIE: 'Movie',
};

export const MOVIE_SCHEMA_ATTRIBUTES = {
  itemType: 'http://schema.org/Movie',
  itemScope: true,
  itemProp: 'itemListElement',
};

export const EPISODE_SCHEMA_ATTRIBUTES = {
  itemType: 'http://schema.org/TVEpisode',
  itemScope: true,
};

export const ITEM_LIST_SCHEMA_ATTRIBUTES = {
  itemType: 'http://schema.org/ItemList',
  itemScope: true,
};

export const STATUS_CODES = {
  UNAUTHORIZED: 401,
  BAD_REQUEST: 400,
  FORBIDDEN: 403,
  INTERNAL_SERVER_ERROR: 500,
  MOVED_TEMPORARILY: 302,
  NOT_FOUND: 404,
  OK: 200,
  TEMPORARY_REDIRECT: 307,
};

export const ESPN_PLUS = 'ESPN+';

export const CTA_BUTTON_STYLES = {
  PRIMARY: 'primary',
  WHITE: 'white',
  BLACK: 'black',
  WHITE_OUTLINE: 'white_outline',
  BLACK_OUTLINE: 'black_outline',
};

export const USER_AGENTS_REGEX = {
  GM: 'GM-FSE|gminfo|AutoRSE',
  TESLA: 'Tesla',
};

export const channelsMap = new Map([
  ['Sports Channels', 'channels_sports'],
  ['Live Local Channels', 'channels_local'],
  ['Entertainment & Lifestyle Channels', 'channels_entertainment'],
  ['Family & Kids Channels', 'channels_family'],
  ['Movies Channels', 'channels_movies'],
  ['News Channels', 'channels_news'],
  ['Add-on Premium Channels', 'channels_premium'],
  ['Entertainment Add-on', 'entertainment_addon'],
  ['Español Add-on', 'espanol_addon'],
  ['Sports Add-on', 'sports_addon'],
]);

// custom events

export const REGISTER_MODALS_EVENT = 'register_modals';

export const DUAL_CTA_MASTHEAD_TYPE = 'dual_cta_masthead';
export const CONTENT_WITH_ANCHOR_TYPE = 'content_with_anchor';
